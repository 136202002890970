import * as React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../components/layout";
import ArticleBlock from "../../components/articleBlock";
import airtable2Data from "../../utils/airtable2Data";
import Tooltip from "../../components/tooltip";
import Divider from "../../components/divider";
import SectionTitle from "../../components/sectionTitle";
import EmojiButton from "../../components/emojiButton";
import Emoji from "../../components/emoji";
import { SEO } from "../../components/seo";
import WebTitleSvg from "../../images/web-title.inline.svg";

// Component

const IdeasPage = ({
  data,
}: {
  data: { allAirtable: { edges: { node: { data: AirtableRecord } }[] } };
}) => {
  const articlesData = airtable2Data(data.allAirtable.edges);
  return (
    <Layout pageTitle="My Creativity - Ideas">
      <div className="max-w-[720px] px-10 py-20 mx-auto">
        {/* section start -- header */}
        <section className="flex flex-col space-y-4 font-nexa-bold mb-14 pt-[10px]">
          <h1>
            <Tooltip message="前往首页" position="top">
              <Link to="/" className="w-fit">
                <WebTitleSvg className="web-title transition-all hover:scale-110" />
              </Link>
            </Tooltip>
          </h1>
          <h2 className="text-4xl">My Ideas</h2>
        </section>
        {/* section end -- header */}
        <div className="mb-6">
          <Emoji emoji="moto-scooter" size={36} />
        </div>
        <section className="flex flex-col space-y-10">
          {articlesData.map((item) => (
            <ArticleBlock content={item} />
          ))}
        </section>
        <Divider className="mt-14" />
        <SectionTitle sectionName="Read More" className="mt-8" />
        <div className="flex gap-3">
          <Link to="/projects">
            <EmojiButton text="My Projects" emoji="musical-score" />
          </Link>
          <Link to="/experiences">
            <EmojiButton text="My Experiences" emoji="man-surfer" />
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allAirtable(
      sort: { fields: rowIndex }
      filter: { data: { Category: { eq: "Ideas" } } }
    ) {
      edges {
        node {
          data {
            Name
            Summary
            Category
            Date
            Tags
            Link
            Slug
            Notes {
              raw
            }
          }
        }
      }
    }
  }
`;

export default IdeasPage;

export const Head = () => (
  <SEO
    title="My Ideas"
    description="Tank Xu 的创意项目，我是一个热爱新鲜技术，喜欢优化工作流程，追求极致效率的设计师。不断创造，富有创意。"
    pathname="/ideas"
  />
);
